import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

// core components
import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"
import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Code"

import LinkIcon from "@material-ui/icons/Link"
import MapIcon from "@material-ui/icons/Map"
import AssessmentIcon from "@material-ui/icons/Assessment"
import CategoryIcon from "@material-ui/icons/Category"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ padding: "60px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h1 className={classes.title}>
            Search Engine Optimization (SEO) Consultation{" "}
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={11}
          align="center"
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            When it comes to improving online presence, don’t look for a quick
            solution. Optimizing your website requires thorough research,
            consistency, and a vast range of knowledge. At Atlas Agency, we
            cover all aspects of search engine optimization. Our services make
            it easier for businesses to navigate in the world wide web.{" "}
          </p>
          <p className={classes.description}>
            Our primary objectives are to improve your online presence, rank you
            higher on Google’s search results, and get the traffic you always
            dreamed of. Contact us to get a quality SEO consultation from one of
            the best SEO companies in Toronto & the GTA.{" "}
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="SEO Audit"
              description="Evaluation of your website, including its Google rank, to find areas that need improvement and SEO practices."
              icon={VerifiedUserIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Keyword Analysis"
              description="Determining which keywords are relevant to your website and have higher chances for driving more online traffic."
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="SEO Roadmap"
              description="Creating detailed plans for optimizing every aspect of your website. Our roadmaps can include short and long-term SEO solutions and strategies."
              icon={MapIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Implementing Strategies"
              description="Working with our team to implement the best SEO strategies for your website. We work nonstop to make sure your website reaches its maximum potential."
              icon={CategoryIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Industry analysis"
              description="Evaluation of your industry and competitors. Analysis of competition is crucial for launching your website at Google’s number one spot. "
              icon={AssessmentIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Link Building and Audit"
              description="Analysis of your backlink profile and developing strategies to improve it if needed. We avoid link stuffing and only use high-quality links that are relevant to your site. "
              icon={LinkIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
