import {
    title
} from "./nextjs-material-kit.js";

const productStyle = {

    section: {
        padding: "10px 0",
        textAlign: "center"
    },
    title: {

        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        "@media screen and (max-width:500px)": {
            fontSize: "28px"
        }
    },
    description: {
        color: "#4c4c4c",
        fontSize: "18px"
    },

    bannerTitle: {
        ...title,
        marginBottom: "1rem",
        fontSize: "25px",
        textDecoration: "none",
        "@media screen and (max-width:500px)": {
            fontSize: "17px"
        }
    },
};

export default productStyle;