import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
// core components
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/banner.js"

const useStyles = makeStyles({
  ...styles,
  banner: {
    backgroundColor: "#213f94",
    backgroundPosition: "center center",
    paddingTop: "10px",
    paddingRight: "30px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    height: "80px",
  },
})

export default function ProductSection() {
  const classes = useStyles()
  return <div className={classes.banner}></div>
}
