import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components

import Grid from "@material-ui/core/Grid"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "seo-consultation.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)

export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div
      className={classNames(classes.section, classes.sectionDark)}
      style={{ padding: "60px 0px 60px 0px" }}
    >
      <div>
        <Grid container spacing={5} justify="center">
          <Grid item md={5} sm={5} lg={5} xs={10}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="seo-consultation"
            />
          </Grid>
          <Grid item md={6} sm={8} lg={5} xs={11}>
            <div>
              <h3 className={classes.title}>
                Launch Your Website With Atlas Agency
              </h3>
              <h6 className={classes.description}>SEO Consultation</h6>

              <p className={classes.description}>
                The rules of search engines are constantly changing. If a
                certain SEO strategy worked for you yesterday, it might not
                provide the same results tomorrow. Professional SEO consultants
                and experts are always up-to-date with trends and SEO practices.
                They also understand the clients’ main objectives, providing
                quality SEO solutions and thorough industry analysis.
              </p>
              <p className={classes.description}>
                {" "}
                At Atlas Agency, we are 100% focused on our client’s needs. We
                deliver on-point and effective strategies that are compliant
                with the ever-changing rules of search engines. We help our
                clients surpass competitors and gain organic traffic from their
                target audience.{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
