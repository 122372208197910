import { title } from "./nextjs-material-kit.js"

const productStyle = {
  section: {
    padding: "10px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    padding: "0px 10px 0px 10px",
    textDecoration: "none",
    "@media screen and (max-width:500px)": {
      fontSize: "28px",
    },
  },
  description: {
    color: "#4c4c4c",
    textAlign: "left",
    fontSize: "20px",
    padding: "0px 10px 0px 10px",
  },
  image: {
    width:"100%",
    height:"440px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",

    "@media(min-width: 768px) and (max-width: 1024px)": {
          width:"360px",
          height:"310px",
          position: "relative",
          top: "0px",
          order: 1
      
    },
    "@media(min-width: 328px) and (max-width: 768px)": {

        marginTop: "20px",
        borderRadius: "3px",
        display: "block",
        width:"100%",
        height:"300px",
        top: "0px",
        position: "relative",
        boxShadow: "0 8px 6px -6px black",
        order: 1

    },
  },
  bannerTitle: {
    ...title,
    marginBottom: "1rem",
    fontSize: "25px",
    textDecoration: "none",
    "@media screen and (max-width:500px)": {
      fontSize: "17px",
    },
  },
}

export default productStyle
