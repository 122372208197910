import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Have you ever wondered why some websites rank higher on search
            results than others? The main reason is that these websites are use
            SEO and make it easier for search engines to find and place them in
            the highest relevant results.{" "}
          </p>
          <p className={classes.description}>
            At Atlas Agency, we use proven SEO techniques to optimize your
            website. With our quality SEO strategies you will eliminate the need
            for paid Google ads. Our team uses white hat methods to help
            businesses reach the number one spot in search results, bringing
            them closer to potential customers and buyers.{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
