import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Grid from "@material-ui/core/Grid"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxConsultation.jsx"

import Marketing from "../pages-sections/SEOConsultation/Marketing.jsx"
import Banner from "../pages-sections/SEOConsultation/Banner.jsx"
import Info from "../pages-sections/SEOConsultation/Info.jsx"
import Intro from "../pages-sections/SEOConsultation/Intro.jsx"
import Contact from "../pages-sections/SEOConsultation/Contact.jsx"

import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles(styles)

export default function Maintenance(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title=" SEO Consultation | SEO Toronto | Atlas Agency"
        description="SEO Consultation agency in Toronto, we help businesses of all sizes grow their online presence through Google's search engine."
        canonical="https://atlasagency.ca/seo-consultation/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />

      <Parallax filter className={classes.parallax} responsive>
        <div className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <h2 className={classes.title}> SEO Consultation </h2>
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ overflowX: "hidden" }}
      >
        <div className={classes.container}>
          <Intro />
        </div>
        <Marketing />
        <Info />
        <Banner />
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
