import {
  container,
  main,
  mainRaised,
  title,
  whiteColor,
  grayColor,
  section,
  hexToRgb,
  sectionDarkMarketing,
  sectionDarkSEO,
  sectionDark,
  sectionDescription

} from "./nextjs-material-kit-pro.js";

const componentsStyle = {
  main,
  mainRaised,
  parallax: {
    height: "70vh",
    overflow: "hidden",
    backgroundPosition: "center top"
  },

  description2: {
    color: "black"
  },

  svg: {

    

  },

  container: {
    ...container,
    zIndex: "2",
    color: "#FFFFFF",
    position: "relative"
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    },
    "& h3": {
      fontSize: "1.313rem",
      maxWidth: "500px",
      margin: "10px auto 0"
    }
  },
  title: {
    ...title,
    color: "white" + "  !important",
    marginTop: "0px",
    marginBottom: "25px",
    minHeight: "32px",
    "@media screen and (max-width:500px)": {
      fontSize: "28px"
    }
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },

  section: {
    ...section,
    padding: "80px 0px 40px 0px"
  },
  sectionGray: {
    background: grayColor[14]
  },

  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },

  sectionDarkMarketing,
  sectionDarkSEO,

  sectionDark,

  sectionDescription,

  description: {
    color: "white",
    fontSize: "20px"
  },


};

export default componentsStyle;